<template>
    <div class="layout-header-section">
        <div class="header-label col-lg-6 col-12">Live Balance</div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    </div>
</template>

<script>
export default {

}
</script>

<style></style>